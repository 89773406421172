<template>
   <headGoBack :iCustomizdde="true">
      <template #default>
        <span>
          返品・返金及び免責事項
        </span>
      </template>
    </headGoBack>
  <div class="plainTextPage">
    <h1>返品・返金及び免責事項</h1>
    <h2>1. キャンセル・返品・返金について</h2>
    <p>1-1 キャンセルについて</p>

    <p>
      原則として、入金確認後のキャンセル・変更は出来ません。必要な場合は担当者へ連絡し、<span
        class="redFont"
        >その都度判断させて頂きます</span
      >。
    </p>

    <p>1-2 返品について</p>

    <p>
      中国内で不良品や店舗の発送ミスについて、中国の商習慣により、<span
        class="redFont"
        >返送料を負担する</span
      >事で返品が認められる場合があります。
    </p>

    <p>
      日本に到着後、不良品と認められた場合、その原因が当社のサービスの範囲内であれば、状態によって補償案を提示させて頂きます。当社による原因で無い場合は、購入店舗と交渉させて頂きます。その場合、返品交渉に成功した場合でも、通常、売買契約は中国内に
      よるものですので、国際送料正規運賃、関税、その他の経費は会員の負担となります。
    </p>

    <p>
      返品には期限が定められておりますので、返品期限が過ぎて到着したものは返品契約無効となりますので、返金されません。それらの損害についても当社は責任を負いません。
    </p>

    <p>1-3 返金について</p>

    <p>
      商品の返品等がありましても、買い付けを伴う当社の手数料返還は出来ません。不良品の返品や見積り差額が生じた場合、最終出荷精算後、返金要望があれば、２営業日以内にご指定口座に返金させて頂きます。
    </p>

    <p>会員が残金の返金を求める場合の振込手数料は会員の負担とします。</p>

    <p>当社都合で返金する場合の振込手数料は、当社が負担するものとします。</p>

    <h2>2. 免責事項</h2>
    <p>2-1
        <ul>
            <li>・商品が、非正規品、コピー品、模倣品であった場合。</li>

<li>
  ・輸送中に商品が破損した場合の保証は担当配送会社の規約による保証範囲となります。
</li>

<li>・会員様より申告されました情報等に誤りがあった場合</li>

<li>・地震・洪水・津波・などの天災により商品が届けられなくなった場合</li>

<li>
  ・戦争・クーデター・暴動・労働争議などにより商品が届けられなくなった場合
</li>

<li>・商品が、中国税関や、日本税関で返却、没収、焼却処分になった場合</li>
<li>・届いた商品が、イメージ違いやお客様の都合で不要となった場合</li>
<li>
  ・中国特有の運送事情、通関事情等により納期が遅れることがありますが、それに起因した損害等についても責任を負い兼ねます。
</li>
        </ul>
    </p>  

    <p>2-2
      届いた商品不良があった場合は、返品・交換の交渉は致しますが、販売店舗が、必ず対応される訳ではありません。
    </p>

    <p>
      2-3 対応の遅延、通知の遅延により生じた
      <span class="redFont"
        >販売機会損失、価格・送料・為替変動等による損害、その他のあらゆる二次損害についても責任</span
      >
      を負わないものとします。
    </p>

    <p>
      2-4
      当社の管理以外や予測不能な原因によるトラブルや、そのとラブルにより発生したシステムの中断、停止、データ消失、及び不正アクセスなどにより生じた損害、及び当社のサービス利用に関する損害について責任を負わないものとします。
    </p>

    <p>
      2-5
      当社は、ユーザーが本サイトを利用する際に、コンピュータウイルスなど有害なプログラム等による損害を受けないことを保証するものではありません。
    </p>

    <p>
      2-6
      当社の管理以外の天災など予測不能な原因による、政治変動等、不可抗力と認められる理由により、本取引の継続、金銭の授受または取引の継続等が遅延、又は不能となった場合により生じた損害については責任を負わないものとします。
    </p>

    <p>
      2-7
      当社は本サービスに関連して会員が被った損害、損失、費用（本サービスを通じた物品の購入に伴う事故、犯罪行為、契約の取消等に基づく損害等を含みますがこれらに限定されません。）、並びに、本サービスの提供の中断、停止、利用不能、変更及び当社による本規約に基づく会員の情報の削除、会員の登録の取消し等に関連して会員が被った損害、損失、費用に於いて、当社は賠償又は補償する責任を一切負わないものとします。尚、前項及び本項における「損害、損失、費用」には、直接的損害及び通常損害のみならず、逸失利益、事業機会の喪失、データの喪失、事業の中断、その他間接的、特別的、派生的若しくは付随的損害、弁護士費用等の全てを意味します。
    </p>

    <p>
      2-8 海外商品の仕入発送代行の為、クーリングオフ及び PL
      法につきましては適用外となります。
    </p>

    <p>
      2-9
      第三者によって、当社サイトから他社サイトへのリンクが提供されている場合がありますが、当社は、当社サイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
    </p>
    
    <p>
      2-10 保管中の不可抗力による損傷
時間の経過とともに訪れる自然の作用、また環境条件による影響により、お荷物に損傷が生じる場合がございます。これらの不可抗力による損傷について、当社は責任を負いかねます事を予めご承知おきください。梅雨の季節や環境の変化により、保管中に生じる可能性のあるカビや湿気による品質の変質や自然の摂理により生じるこれらの現象は、いかなる対応をもってしても完全には防ぎ得ない事をご理解ください。 長期保管をご希望される際は、上記の免責事項を十分にご理解頂きますよう、宜しくお願い申し上げます。
    </p>

    <p>
      その他詳細について
      <a href="/footListAll?page=termsService"
        ><span
          style="
            background-color: #eeeeee;
            color: #0000ff;
            font-weight: bold;
            text-decoration: none;
          "
          >会員利用規約</span
        ></a
      >より確認も可能です。
    </p>
  </div>
  <footBar />
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
import headGoBack from "@/components/headGoBack";
import footBar from "@/components/footBar/index.vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
defineExpose({});
</script>
  <style lang='scss' scoped='scoped'>
@import "@/css/mixin.scss";
@import "../css/pageFont.scss";
</style>